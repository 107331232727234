var WooCommerceAPI = require('woocommerce-api');

var WooCommerce = new WooCommerceAPI({
    url: 'https://fusska.com',
    consumerKey: 'ck_9f8f697447c2f27d672a8b8ff6730a527d654d68',
    consumerSecret: 'cs_c9f754c2ef20d2ad29cfdea1aa685b87c09f470c',
    wpAPI: true,
    version: 'wc/v1'
});

export default WooCommerce;