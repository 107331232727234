<template>
  <v-app class="backgroundC">
    <v-app-bar app dark color="primary">
      <v-spacer />
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/vuetify-name-dark.png"
          width="170"
        />
      </div>
      <v-spacer />
    </v-app-bar>

    <v-main class="backgroundC">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.backgroundC {
  background: #f4f5fa;
}
</style>