<template>
  <v-container>
    <p>{{ err }}</p>
    <v-card class="hidden-sm-and-down">
      <v-card-title>Siparişler</v-card-title>
      <v-card-text>
        <v-data-table
          :items="data"
          :headers="headers"
          :loading="loading"
          :server-items-length="totalData"
          :options.sync="options"
        >
          <template v-slot:item.image="{ item }">
            {{ item.line_items[0].id }}
          </template>
          <template v-slot:item.customer="{ item }">
            {{ item.shipping.first_name + " " + item.shipping.last_name }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="">
      <v-text-field
        outlined
        class="mx-2 my-0"
        dense
        label="Müşteri/Sipariş No Arama"
        hint="Henüz Aktif Değil"
        background-color="white"
      >
        <template v-slot:append>
          <v-icon>mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <template v-for="d in data">
        <v-card
          :loading="loading"
          outlined
          :key="d.id"
          class="elevation-0 mb-4 mx-2"
          @click="showD(d)"
        >
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-img
                  style="height: 50px; width: 50px; margin-top: 7px"
                  class="image_radius"
                  lazy-src="../assets/vuetify-logo.png"
                  :src="getImageSource(d.line_items[0].product_id)"
                />
              </v-col>
              <v-col cols="9" style="margin-left: -10px">
                <div
                  style="overflow: hidden; white-space: nowrap"
                  class="font-weight-black"
                >
                  {{ d.line_items.length }} x {{ d.line_items[0].name }}
                </div>
                <div style="display: flex">
                  <div class="primary--text font-weight-bold">
                    {{ d.total + " ₺" }}
                  </div>
                  &nbsp; {{ "-" }} &nbsp;
                  <div
                    :class="[getColor(d.status) + '--text', 'font-weight-bold']"
                  >
                    {{ getStatus(d.status) }}
                  </div>
                </div>
                <v-flex
                  class="text-caption mx-0 my-0"
                  style="overflow: hidden; white-space: nowrap"
                  row
                >
                  {{
                    d.id +
                    " | " +
                    d.shipping.first_name +
                    " " +
                    d.shipping.last_name +
                    " | "
                  }}
                  {{ d.date_created | moment("D.MM.Y") }}
                  <!--
                  <v-spacer />
                  <v-icon color="primary">{{
                    d.kargo ? "mdi-truck" : ""
                  }}</v-icon>
                  <v-icon color="primary">{{
                    d.date_paid ? "mdi-currency-usd" : ""
                  }}</v-icon>
                  -->
                </v-flex>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <div class="my-4 mx-2">
        <v-pagination
          v-model="options.page"
          :length="totalPages"
          :disabled="false"
        />
      </div>
    </div>
    <v-dialog v-model="showDialog" max-width="650" class="mx-2">
      <v-card>
        <v-card-title>Sipariş Detayı</v-card-title>
        <v-card-text>
          <div>Sipariş Numarası: {{ selectedItem.id }}</div>
          <div>
            Sipariş Tarihi:
            {{ selectedItem.date_created | moment("D.MM.Y H:m:s") }}
          </div>
          <div class="font-weight-bold">
            Müşteri Notu: {{ selectedItem.customer_note }}
          </div>
          <div :class="[getColor(selectedItem.status) + '--text']">
            Sipariş Durumu: {{ getStatus(selectedItem.status) }}
          </div>
          <div>Ödeme Yöntemi: {{ selectedItem.payment_method_title }}</div>
          <div>Toplam Tutar: {{ selectedItem.total }}₺</div>
          <div>
            Ödeme Tarihi: {{ selectedItem.date_paid | moment("D.MM.Y H:m:s") }}
          </div>
          <div>Kargo Kodu: {{ kargoKodu }}</div>
          <br />
          <div
            class="font-weight-bold"
            v-for="line_item in selectedItem.line_items"
            :key="line_item.id"
          >
            {{ line_item.quantity }} x &nbsp; {{ line_item.name }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            width="100%"
            class="mb-2 py-6"
            @click="kargoKoduOlustur()"
            ><v-icon>mdi-truck</v-icon> &nbsp; Kargo Kodu Oluştur</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import WooCommerce from "../plugins/woocommerce";
export default {
  title: "Fusska Yönetim Paneli",
  components: {},
  data() {
    return {
      loading: true,
      data: [],
      totalData: 0,
      totalPages: 0,
      images: [],
      selectedItem: {},
      err: "",
      showDialog: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      kargoKodu: "",
      headers: [
        { text: "Ürün Görseli", value: "image" },
        { text: "Sipariş No", value: "id" },
        { text: "Müşteri Tanımı", value: "customer" },
        { text: "Ödeme Şekli", value: "payment_method_title" },
        { text: "Şehir", value: "shipping.city" },
        { text: "Toplam Tutar", value: "total" },
        { text: "Sipariş Durumu", value: "status" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.loading = true;
      WooCommerce.getAsync(
        "orders?page=" +
          this.options.page +
          "&per_page=" +
          this.options.itemsPerPage
      )
        .then((result) => {
          this.data = [];
          this.images = [];
          this.data.push(...JSON.parse(result.toJSON().body));
          this.data.forEach((element) => {
            fetch(
              "https://fusska.com/panel_api/image.php?id=" +
                element.line_items[0].product_id
            )
              .then((response) => response.json())
              .then((resp) => {
                this.images.push({
                  product_id: element.line_items[0].product_id,
                  image: resp,
                });
              });
            axios
              .get(
                "https://fusska.com/panel_api/kargo_check.php?id=" + element.id
              )
              .then((response) => {
                if (response.data != false) {
                  element.kargo = response.data;
                }
              });
          });
          this.loading = false;
          this.totalData = Number(result.headers["x-wp-total"]);
          this.totalPages = Number(result.headers["x-wp-totalpages"]);
        })
        .catch((ex) => {
          this.err = ex;
        });
    },
    getStatus(status) {
      switch (status) {
        case "processing":
          return "Açık";
        case "cancelled":
          return "İptal";
        case "completed":
          return "Kapalı";
      }
    },
    getColor(status) {
      switch (status) {
        case "processing":
          return "green";
        case "cancelled":
          return "red";
        case "completed":
          return "grey";
      }
    },
    getImageSource(id) {
      let x = this.images.find((elm) => elm.product_id == id);
      return x == null ? "" : x.image;
    },
    showD(selected) {
      console.log(selected);
      this.selectedItem = selected;
      this.kargoKodu = selected.kargo ? selected.kargo : "Yok";
      this.showDialog = true;
    },
    kargoKoduOlustur() {
      let data = {
        order: {
          referenceId: this.selectedItem.id,
          barcode: this.selectedItem.id,
          billOfLandingId: "",
          isCOD: 0,
          codAmount: 0,
          shipmentServiceType: 1,
          packagingType: 1,
          content: this.selectedItem.id + " Nolu Sipariş",
          smsPreference1: 1,
          smsPreference2: 0,
          smsPreference3: 0,
          paymentType: 1,
          deliveryType: 1,
          description: this.selectedItem.id + " Nolu Sipariş",
          marketPlaceShortCode: "",
          marketPlaceSaleCode: "",
          pudoId: "",
        },
        orderPieceList: [],
        recipient: {
          customerId: 623995731,
          refCustomerId: "",
          cityCode: 0,
          districtCode: 0,
          address: "",
          bussinessPhoneNumber: "",
          email: "",
          taxOffice: "",
          taxNumber: "",
          fullName: "",
          homePhoneNumber: "",
          mobilePhoneNumber: "",
        },
      };

      this.selectedItem.line_items.forEach((element) => {
        data.orderPieceList.push({
          barcode: element.sku,
          content: element.name,
        });
      });

      axios
        .post("https://fusska.com/panel_api/kargo_create.php", data)
        .then((resp) => {
          if (resp.data.error) {
            this.$fire({
              title: "Hata",
              text: resp.data.error.Description,
              type: "error",
            });
          } else {
            this.$fire({
              title: "Tebrikler",
              text: "Kargo Kodu Başarıyla Oluşturulmuştur!",
              type: "success",
            });
            axios
              .get(
                "https://fusska.com/panel_api/kargo_check.php?id=" +
                  this.selectedItem.id
              )
              .then((response) => {
                if (response.data != false) {
                  this.selectedItem.kargo = response.data;
                  this.kargoKodu = response.data;
                }
              });
          }
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.image_radius {
  border-radius: 10%;
}
</style>