import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#9155fe",
                background: "#f4f5fa"
            },
            dark: {
                primary: "#9155fe",
                background: "#f4f5fa"
            },
        },
    },
})

Vue.use(Vuetify);

export default vuetify;
